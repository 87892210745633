import request from "@/utils/request";

// 日语APP课程列表
export function CourseList(params) {
    return request({
        url: '/admin/japanese_v3/courseList',
        method: 'get',
        params
    })
}

// 删除日语APP课程
export function CourseDel(data) {
    return request({
        url: '/admin/japanese_v3/courseDel',
        method: 'delete',
        data
    })
}

// 添加日语APP课程
export function CourseAdd(data) {
    return request({
        url: '/admin/japanese_v3/courseAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP课程
export function CourseEdit(data) {
    return request({
        url: '/admin/japanese_v3/courseEdit',
        method: 'put',
        data
    })
}

// 每日一句上传COS签名
export function getCourseCosToken(params) {
    return request({
        url: '/admin/japanese_v3/getCourseCosToken',
        method: 'get',
        params
    })
}

// 日语APP课程类型
export function CourseType(params) {
    return request({
        url: '/admin/japanese_v3/courseType',
        method: 'get',
        params
    })
}

